import {isWx, wxAuthorize} from '@/utils/tool'
export default [
  {
    path: "/bind",
    name: "绑定服务号",
    component: () => import('../views/bind'),
    beforeEnter: (to, from, next)=> {
			if (to.query.code === undefined) {
				wxAuthorize('/bind')
			} else {
				next()
			}
    }
  },
	{
		path: '/orderPay/:id',
		name: `订单支付`,
		component: ()=> import('../views/orderPay'),
		beforeEnter: (to, from, next)=> {
			if (isWx() && to.query.code == undefined) {
				wxAuthorize(`/orderPay/${to.params.id}`)
			} else {
				next()
			}
		}	
	}
]