import http from '@/utils/http'
/**
 * @description 是否微信环境
 * @return {boolean}
 */
export const isWx = () => {
	return window.navigator.userAgent.toLowerCase().match(/MicroMessenger/i) == "micromessenger"
}

/**
 * @description 是否在支付宝环境
 * @return {boolean}
 */
export const isAli = () => {
	return window.navigator.userAgent.toLowerCase().match(/Alipay/i) == "alipay"
}

/**
 * @description 获取跳转地址
 * @return {string}
 */
export const wxAuthorize = (uri) => {
	http.request({
		url: "index/wechat/redir",
		params: {uri},
		success(res) {
			location.href = res.uri
		}
	})
}